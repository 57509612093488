import { Injectable } from '@angular/core'

import { VenueService } from './venue.service'

import { environment } from '../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class RedirectToNewLiveMenuService {
	constructor(private venueService: VenueService) {}

	checkAndRedirect() {
		const gurumeVenueIds = [
			'56c777520896b3cd13c603dc',
			'5c543af89fb620aa38a3f2dd',
			'5d25f2770a4ababa6db45949',
			'5d7297e70a4ababa6d27c034',
			'5da612ab0a4ababa6ddee6fc',
			'643da1c636739a00555c5d1d',
			'64fb87bf3188ad005b76e9bd',
			'6525719b85a1bc005b48aa94',
			'65d7490fda37e20071b3bfcf',
		]
		const jojoRamenVenueIds = [
			'65c3a124b4cea6006ab61f30',
			'65c291c3b4cea6006ab5865f',
			'65c13285b4cea6006ab4a403',
		]
		const nolita = ['5aba949bfa271c9ee3c3ea28']
		const tocaDaTraira = [
			'5b7c3ec4fa271c9ee3c4ecb2',
			'5b7c3ec0fa271c9ee3c4ecae',
			'5bbe0db5fa271c9ee3c573dc',
			'5b7c3ebdfa271c9ee3c4ecac',
			'5bb4c9bffa271c9ee3c567b4',
		]
		/* const barDoZecaPagodinho = [
			'5f32db362d81be01265dce36',
			'615a58ce87c266002005987c',
			'6377c7915da840004aae0ff0',
			'65eca96ae2227b00692a272a',
			'6601dd468d0d04007195cc69',
		] */
		const huto = [
			'5dadb42e0a4ababa6d7b4905',
			'60a29b96002a6c00359fbd4b',
			'6499dfe1a514d70055ced667',
			'60a27ca6cf5fcf0035b84bf3',
			// '60a67354e4f9200b74a4f96b',
		]
		const jappaDaQuitanda = [
			'61a661ecd999480021ea4f92',
			'61a67792657b3d0012fc26fe',
			'63a48c51e7a6da0052821621',
			'638f55a1611c71004ae06cd0',
			'65672c8b5dc698005aaa2efa',
		]
		const paris6 = [
			'65033d4c9f4693005586135c',
			'660afa5814033c006c69d095',
			'660afaa072931900711cf005',
			'660afb1c9f7b7e0072c743c8',
			'660afb279f7b7e0072c743ce',
			'660afb3357b01e0071622314',
			'661693c5ac711d007146ad47',
			'66169666ac711d007146ae4a',
		]
		const cabanaBurger = ['66b3beb81c9eeb89ca9bc02d', '66b3bed51c9eeb89ca9bc44a']
		// const jam = ['6454f3ac93c09d005616c873', '645a88527ac866005475cf48']
		const macaw = ['666afb52d91d31d1820e3f52', '666c5b1d6166183b61ee88e8']
		const ninoCucinaVino = ['5b0563f4fa271c9ee3c47487']
		const daMarinoIpanema = ['63b429c4322cc500528437e3']
		const ninettoRioDesign = ['663b9dfb8d69d50055987705']
		const giuliettaCarni = ['5ca39f5322db8f849ea3b8c2']
		const oliveGarden = [
			'5e583824e130c46f6b2746f7',
			'5eda5d935b6cc0319c382d69',
			'5eda743a5b6cc0319c38c81b',
			'5eda7e055b6cc0319c39c61c',
			'5edfeff2aab67728ba26e1fc',
			'6012c68e117fb85dccc1745d',
		]
		const yusha = ['60c8ecb85f041f4364d52704']
		const _1900 = [
			'5b085402fa271c9ee3c47671',
			'5b0c0e35fa271c9ee3c47751',
			'5b0c12b6fa271c9ee3c47841',
			'5b0c23f5fa271c9ee3c47981',
			'5b0c52c6fa271c9ee3c47d0c',
			'5b0c64b3fa271c9ee3c47da7',
			'5b0d82fffa271c9ee3c481ae',
			'61ae1b8298e46a002c0e4da3',
		]
		const siSenor = [
			'621641579080f300118eec98',
			'6216444eeed8b20012909790',
			'6216446f8c5bd400111c9202',
			'6319e7094b00e30012388a0a',
			'64dcd6e89d74cf005413aeb1',
			'64dcdc93176e7600551d37ce',
			'64dcde37257f1100552ce6e1',
			'64dce27c176e7600551d3864',
			'64dce54e627a68004f57fd5a',
			'64dceb202276a40052b78431',
			'64dcedfd0a54280054fbc2c2',
			'64dcf0b4257f1100552ce94f',
			'64dcf3409d74cf005413b1e1',
			'64dd073504a6d40051b5ccec',
			'64dd0b669d74cf005413b4a0',
			'64dd0d33176e7600551d3e4f',
			'64dd152e257f1100552cee02',
			'64dd16bba93538004ff10b94',
		]
		const gulaBar = ['64676fa8d1f2db0055fc38cf', '66a3d76c4fe4c7e0046c9d9d']
		const kiichi = ['5ae38ccafa271c9ee3c45703', '5d701fcc0a4ababa6d080f54', '5e9483ede130c46f6bc96b09']
		const ludwig = ['5c7431eb9fb620aa38a4384e']
		const badalado = [
			'6349b905f6cdac004a482478',
			'63727b82738ee3004a95a9fe',
			'648a0ef2d7f12d004f1930b8',
			'64aff34c5cfbc400550da83f',
			'64b94977c4ead2004e169c73',
			'652570aa85a1bc005b48a8c9',
		]
		const macaxeira = [
			'61fc2c728966f0001265dac3',
			'61fd1e048966f00012660a66',
			'631f7824eb6971002092dd43',
			'632090983130e30011e7078d',
			'632092d04b00e300123a1595',
			'6320c0a1eb69710020932b52',
			'6377e4c4f73d62004a69d971',
			'63a35a275def5d00522db078',
			'641d9f14131832005c62649a',
			'64808a43113b1c004e77952d',
			'649c3553e13ae000553be328',
			'64de18512276a40052b798f3',
			'653032b04790f4005b31e1f0',
			'653fe39bfbc78b005a79ab27',
		]
		const salGastronomia = ['5e2f133c0a4ababa6db87f82', '5ff706ea53ea855a788f185b']
		// const bottegaDelMare = ['65c66a51b4cea6006ab87041']
		// const micale = ['63ab420ec2b0270052dd07c7']
		const pipasTerroir = ['64d3926cf4cca00054b86ae1']
		const laMaddalena = ['6509a541cb79c6005343fdc6', '6509a739b532300055dad32d']
		const donnaMargherita = ['65c65202b4cea6006ab85325', '65ce1316b4cea6006abea895']
		// const tasteIT = ['5cae299222db8f849ea3c1ab']
		// const lourdesSquare = ['644a9ad5f8307e0056bff0f5', '629fa3b9baad510011d0abe0']
		// const outbackVenueIds = ['66675f0a6bd83c4418d2c5d8']
		const scadaCafe = [
			'63875e2b0795e7004a0fe30a',
			'638df753e424ed004a978d3b',
			'638dfe3ed8d40a004a427f9b',
			'638e04580795e7004a11124e',
			'638e087a0795e7004a1112c6',
			'638e0cab10eead004a44e767',
			'649c47175bbf100055535b1e',
			'649c472db04c4c00553b6b75',
		]
		const giardinoTrattoriaVogue = ['6679c96f332beda5c7d788ae']
		const curadoria = ['62d9484a505d230020bae5ed']
		const minimok = ['56c777b90896b3cd13c6076c', '56c777ba0896b3cd13c6077c']
		const dryMomentsDrinks = ['5fbd193eec61af0035e369a1', '5fb42191e57bf200357d2e88']
		const botegare = ['6570dc7a20b293007222f78b']
		const nemoSushi = ['664ca39a8ea2ce06a39edd51']
		const ambaiHallFestas = ['6358369da90f29004e929e54']
		// const cocoBambu = ['63768e3d9ebb9300477552a8']
		const taypa = ['56c778110896b3cd13c60a7e']
		const fairmont = [
			// '61697a77513c3c001259d91b',
			// '61a7c63a5e7bba001201b6d1',
			// '616f06ac73f192001148f4d2',
			'6195641f5e7bba0012fe1288',
			'66ec3d48aa73d02255c6b67a',
			'66ec560aaa73d02255ccb727',
		]
		const selDIpanema = ['638a1c140c7c3a004ab338ad']
		const soLoLeblon = ['66cbe5ba3f9d6b5078bff5cb']
		const coaCo = ['61a6392f2e30a400219312e5']
		const kinjo = ['5f622e6d22f66201531ad499']
		// const taverna = ['5f078414797aafef848a42d0']
		const cedilha = ['603d1fcdf0c73b2e108b9c75']

		const chicago = [
			'66db33e222a75a1032ff1868',
			'66e2fb5f768644578d7a15a8',
			'66e310523a9b3eaf6d64196b',
			'66e2e0339e1c9a2c48698f2f',
			'66de498a22a75a10327b24f5',
		]
		const pontaoChard = ['66d9cac022a75a1032d6295c']
		const outback = [
			'5f76737507ccc4038480a4c7',
			'5f7381b90848d6236efed81b',
			'5f7670bee5fa5a0358dbdf17',
			'5f281515be0506065b0051cd',
			'5f7376aefd690e013c8b2bcd',
			'5f7cc9e2700e870a34948e0f',
			'5f766db5aaeaa4030b11c36c',
			'5f766e1f37db2f031622676e',
			'5f73a5027eb3199980de4e6a',
			'5f73783b90373007e3937acc',
			'5f766bca511c3502df0887aa',
			'5f766c53b1c1b702eaed42f3',
			'5f73a5f74649349c8ea36340',
			'5f766cc275281d02f53da3ba',
			'5f7dde0b4d1f250a97425839',
			'5f766d2d9125fc030095c4be',
			'5f74dc1d3c415c748d8185bc',
			'5f766e7a34516503219ce018',
			'5f73a3a3b712f795475ae0d7',
			'5f77703fcb1af7dc7c0a7dba',
			'5f7e0cfa2272ad0b94385fe2',
			'5f74e075f523e481f7935211',
			'607d85197b84f128406b8eda',
			'5fc50729b56e2b64f88af35c',
			'622b5ae92c906b0011c149fd',
			'63bec331f288dd00527440c1',
			'64199a09ced4d4005ca5e45d',
			'65a13e919ff2c900713138be',
			'5f7f3a9a33823e41b8e8024d',
			'64399de02ad1e4005c369644',
			'5f23197b085ad7259b8e01ef',
			'62b5b08ef2d4ef00117d6fd4',

			// Group B
			'5f777cc38846f701129e7df5',
			'5f7cb9b567141342cc4a22ac',
			'5f777d7639404402f5afbfc8',
			'5f7cc333695d2b0a08dbd455',
			'5fae88da6e93610036fabb10',
			'6035356260fb800b50a75d54',
			'628ba596e1074c00116a0b60',
			'62c2e08a9d3873002034dc4e',
			'64354f42131832005c649349',
			'6467764cbeefc2005564d63b',
			'65d3ae48b4cea6006ac44194',
			'5f7f39febb7c283feee28d69',
			'5f7e01fb6317ce0b26431214',
			'5f7e02786258600b31d878fb',
			'5f7f3b5274af684360205faf',
			'5f7e0072ed8d2a0afa57afe8',
			'5f7f323732fcb52caf53758b',
			'5f7e010b21a6360b05d36576',
			'5f7e10e6fccbe30baab54fd8',
			'5ff8a8e1532fc457e82058ca',
			'6154acdd948ffa004164edb2',
			'62a0c23a5405fe00111d48ea',
			'667323248943d856bdf9e61c',
			'66747b29e34c0a9d43763e2e',
			'5f7e0c794efaa80b89c1220d',
			'64bfbe85e487510051b86397',
			'666314eec64447f3cb8050eb',
			'5f7f389b049cc93c38bf3429',
			'5f77797d8a4df8f6fa7afc1e',
			'66630e4ae34c0a9d43b43614',
			'67100255c6fdd78533324a59',

			//
			'5f7671694508c8036e665cca',
			'5ca3aaf822db8f849ea3b8fe',

			// Grupo A
			'5f77763e52df65ed8731572e',
			'5f74e6ef82ecb2949ed6ffa6',
			'5f74e4badcd3578e85c06f25',
			'5f766f62ec7630033736167b',
			'5f73a46c87a5189795b7faed',
			'5f777df5fd8b280485efdbe6',
			'5f7cc39f37deaf0a13b6bf33',
			'5f7cbb19d4747b46b466c671',
			'5f7e0a996420090b5dfe123f',
			'5f776e3bdf088cd3f1f403a1',
			'5f7dfe4d3ebe770ad9183612',
			'5f7cb7cf3063123c77aefdf8',
			'5f73842375158a2c97ed4f44',
			'5f7ddd92a79cd30a8c9f6415',
			'5f77755feca990ea777e7fcb',
			'5f7cb87d36e00c3ee3cc7058',
			'5f7e0a24a190710b52653f94',
			'5f7cc45d1f1dce0a2936280f',
			'5f777fc6f0319109551ddfb3',
			'5f738059fc410220024a79f2',
			'5f776ef4463e5cd7bad57d3b',
			'5f7776cc44cc79f02ecacfbe',
			'5f776f888370edd95ad96749',
			'5f74e8f33c3a59998a14ef21',
			'5f7cba56bc522b4480cf24b4',
			'5f7670584dc27e034dc179ac',
			'5f777142115dd2df4f3693dc',
			'5f74dfe2ee1a828058466a51',
			'5f766f047ee654032c0366d1',
			'5f7cb9212c2bda40d9fb161e',
			'5f74e586e77c6e90e5206c5d',
			'5f74e1fed1e4ac86177ce5f1',
			'5f74e15ad402588485110860',
			'5f7cbc251333b94b4e77e747',
			'5f74e400df371e8ba57778c6',
			'6172f5cef9e1020011e2e2b9',
			'6172f5cef9e1020011e2e2b9',
			'6126a79dda57340021868523',
			'6126a78a8b7ad900202fce1b',
			'60a2c0cd25fea14b2ceb236f',
			'60be2f221d460144ec685d47',
			'5f7770d0ad4efeddfcf3503a',
			'62600755474a300012051d27',
			'62600761e7f24b00129b39fb',
			'62b5b09ad8b4f30011d842bd',
			'63fdf61911a5ae0051d29074',
			'64dbd495257f1100552cd8e3',
			'64b13e3a5cfbc400550dc92d',
			'65d4ef5fda37e20071b22c62',
			'65b3f89565957a0070097d47',
			'663e6da6fe872f00556c003f',
			'613b546c0df350002c06522f',
			'5f7dff90af8f8f0aef0cc9e7',
			'5f7ddb7e51a8dd0a762880c5',
			'5f7e0b25f4948a0b68bb55e6',
			'5f7dfdbe10185a0acef61241',
			'5f7e0b8bc5d8150b737f797e',
			'5f7e0bf4198de40b7e95cd16',
			'5f7e01966aa0d50b1bd8ce26',
			'628ba58bd139d90011dbf6dc',
			'667326bf8943d856bdfa35d8',
			'654254d6fbc78b005a7b4bd2',
			'65e85e3ae2227b006925ea79',
			'5f7cc29d6a136709fd315dea',
			'5f7dfed72d4bb90ae42d0c7c',
			'5f7cc3ffe73f3f0a1e77d4dd',
			'5f766ff86b9ce2034243a3ac',
			'6070b06cfe08053b58823ba1',
			'622b5ad12c906b0011c149ec',
			'62790d95b0f2b200123a2a7b',
			'62fa7daaac2b4c00112feb0a',
			'6172f562f1286c0011a6f09d',

			// Grupo B (sem homestead)
			'5f7ddee3f67dbe0aadad6708',
			'5f8076b48cd6e4ccdaa1ec2c',
			'5f807999272d3cd5926cd7de',
			'5f7dde7acf16c90aa28ffcaf',
			'5f7f393b0bbb123e197d06af',
			'5f7f3c78bafc8246533b5bd4',
			'5f7cca5ac8cc430a3fe96149',
			'5ff8ade3e13447581cbcd979',
			'63f8b20bb5a9650052276e50',
			'642723f956ee0d005c1c90e1',
			'645a5931f8307e0056c16546',
			'643d788436739a00555c593f',
			'647dd7a545e07a00556c240b',
			'655cf792ab8679005a9ddfae',
			'65f3177fe2227b00692c59e0',
			'5f7f4f561d8c2587836a2f51',
			'5f7ddf4b9249770ab81e6b3e',
			'62fa8263de681e0011f3dc3f',
			'66747b29e34c0a9d43763e2e',
			'6661ff1aa082bb3de0589976',

			// Grupo C (sem homestead)
			'5f7f5b92959b14b72ffc06bd',
			'5f7f5c2edd07b3b91b4caa39',
			'62bb150a2f2a9900118de627',
			'630e792072ae8e002b92f7d6',
			'6419a3499c155f005c2727ed',
			'663e6af9e1b0ab00544b4af5',
			'622b5adfc2f1cb0011419ebe',
			'5f7f58aadcd672ad8aa26130',
			'5f7f5960198a1db131037bb4',
			'603e8b8f8ffb832dccb73e1d',
			'5fc50295aac7265ec0c5c0fc',
			'63f8b9cc051cbc00520edc75',
			'65a030940b93d7007242bb6c',
			'65f31e31e2227b00692c5ee1',

			// Grupo E
			'5f7f28cab8979a3c464d5db8',
			'5f7f2f115c8d701e8b91f5c9',
			'5f7f312206c4fc29b30804e4',
			'5f807488d84384c66d82bb00',
			'60be2f112fc7041de8619965',

			// Others (clusters)
			'664b9b08be11653c62b7064b',
			'66e1aa8b22a75a1032ba76ce',
			'66e1aa9e22a75a1032ba78d3',
			'66e1aab722a75a1032ba7eda',
			'66e1aac122a75a1032ba8036',
			'66e1aac822a75a1032ba81e3',
			'66ed7dc2aa73d02255ece1fc',
		]

		const abraccio = [
			'5f3c30cba748257e56b0984f',
			'5f3d37a078714b814dd699e5',
			'5f3d8137336a4163513d9929',
			'5f3d40a55b5c499b007cc463',
			'5f3d8f467a85610138ad2b8f',
			'5f3d47c33aecccad19bae633',
			'5f2067887e46d0ea71dc8a8b',
			'5f3c33a5568f658aa4327595',
			'5f3c328635f8708568237973',
			'5f3d9932b8ee00014385c863',
			'5f231b4f9b65772da7201fce',
			'5fff743e864a953bace2e71c',
			'630f6e55e4a88300360b4106',
			'64c15db5d70c230053415ab3',
			'64c916a6f4cca00054b7847a',
			'5f3c05f040f1fdf4c9e9b462',
			'653a5d47fbc78b005a73f197',
			'663a46b1c74e460058757e48',
		]

		const allowedVenueIds = [
			...gurumeVenueIds,
			...jojoRamenVenueIds,
			...nolita,
			...tocaDaTraira,
			// ...barDoZecaPagodinho,
			...huto,
			...jappaDaQuitanda,
			// ...paris6,
			...cabanaBurger,
			// ...jam,
			...macaw,
			...ninoCucinaVino,
			...daMarinoIpanema,
			...ninettoRioDesign,
			...giuliettaCarni,
			...oliveGarden,
			...yusha,
			..._1900,
			...siSenor,
			...gulaBar,
			...kiichi,
			...ludwig,
			...badalado,
			...macaxeira,
			...salGastronomia,
			// ...bottegaDelMare,
			// ...micale,
			...pipasTerroir,
			...laMaddalena,
			// ...donnaMargherita,
			// ...tasteIT,
			// ...lourdesSquare,
			...scadaCafe,
			...giardinoTrattoriaVogue,
			...curadoria,
			...minimok,
			// ...dryMomentsDrinks,
			...botegare,
			...nemoSushi,
			...ambaiHallFestas,
			// ...cocoBambu,
			...taypa,
			...fairmont,
			...soLoLeblon,
			...selDIpanema,
			...coaCo,
			...kinjo,
			// ...taverna,
			...cedilha,
			...chicago,
			...pontaoChard,
			...outback,
			...abraccio,
		]
		const currentVenueId = this.venueService.getVenueId

		const isNotPrintPage = !window.location.href.includes('print')

		if (
			environment.production &&
			currentVenueId &&
			allowedVenueIds.includes(currentVenueId) &&
			isNotPrintPage
		) {
			const currentUrl = new URL(window.location.href)
			const queryParams = currentUrl.search
			const newMenuUrl = `${environment.newLiveMenu}/pt/menu/${currentVenueId}${queryParams}`
			window.location.href = newMenuUrl
		}
	}
}
